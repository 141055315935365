<template>
  <div class="c-meet-the-team">
    <h2 class="c-team__title">Digital Asset Solutions Team</h2>
    <!--<h3 class = "c-meet-the-team__subtitle">of digital assets experts for institutional clients</h3>-->
    <div class="c-team__container">
      <div class="c-team__item" v-for = "(member,idx) in members"  :key="idx" @click="showModal(member)">
        <img :src="member.photo" :alt="member.name" class="c-team__photo">
        <div class="c-team__info">
          <h3 class="c-team__name">
            <span class="c-team__name-text">{{member.name}}</span>
            <div class = "c-team__arrow-container">
              <span class = "c-team__arrow">></span>
            </div>
          </h3>
          <p class="c-team__role">{{member.role}}</p>
        </div>
      </div>
    </div>
    <modal
      v-if="isModalVisible"
      @content="userSelected"
      @close="closeModal"
    >
      <template v-slot:name>
        <h2 class="c-about-modal__name">{{userSelected.name}}</h2>
      </template>
      <template v-slot:role>
        <h3 class="" :class="{'c-about-modal__content-short': userSelected.name == 'Jonathan Yu'}">{{userSelected.role}}</h3>
      </template>
      <template v-slot:content>
        <p class="c-about-modal__content" v-html="userSelected.content"></p>
      </template>
    </modal>
  </div>
</template>

<script>
import $ from 'jquery'
import modal from '@/components/about/ModalTeam.vue'
export default {
  name: 'MeetTheTeam',
  components: {
    modal,
  },
  data(){
    return{
      members:[
        // {
        //   name:'Ang Kok Wee',
        //   role: 'Chief Revenue Officer',
        //   photo:require('@/assets/images/team/Ang Kok Wee-2.png'),
        //   content: 'With 2 decades of experience in FX derivatives trading, Kok Wee specializes in product structuring and risk management. He is also adept at financial modeling and real estate valuation. Previously with Citigroup, Kok Wee was the head of FX Options Desk, delivering track records in desk profitability and desk expense optimization. In addition, he spearheaded the integration of European, Middle East, and Africa franchises into existing portfolios, and boosted assets under management by 20%. As the Chief Revenue Officer, Kok Wee will oversee the aligned Sales and Trading functions. He aims to synergize the teams to continue to deliver exceptional trade execution standards and client experiences. By constantly enhancing our suite of bespoke digital asset solutions, Kok Wee believes it will help bridge traditional finance and deliver sound portfolio management and diversification solutions to our clients which include institutions, wealth managers and accredited investors.'
        // },
        {
          name:'Tan Ming Hoe',
          role: 'Head of Trading',
          photo:require('@/assets/images/team/Tan Ming Hoe.png'),
          content: 'Ming Hoe has over 13 years of experiences covering areas of FX, equities & commodities trading. Started as a FX Derivatives Dealer with OCBC for 6 years, Ming Hoe then joined numerous family offices to broaden his knowledge of the financial markets while venturing into algorithmic trading. Ming Hoe also accumulated specialization in programming languages including C# and Python. In his academic years, he attained a Merit in MSc Quantitative Finance from CASS Business School London and First-Class Honors in BSc Electronics and Electrical Engineering from University of Edinburgh. As the Head of Trading, Ming Hoe focuses on risk management on Spot and Derivatives flow, ensure optimal execution of trading strategies, and transactions are conducted in accordance with high regulatory compliance.'
        },
        {
          name:'Faith Ng',
          role: 'Deputy Head of Sales (Singapore)',
          photo:require('@/assets/images/team/Faith Ng-2.png'),
          content: 'Faith has over 10 years of professional experience as a relationship manager managing quality account management with leading brands including Standard Chartered Bank and Shangri La Hotel Singapore. Engaged in diverse industries including commercial banking and hospitality, she has work exposures in Australia, China and Indonesia. As the Deputy Head of Sales of the Singapore market, Faith is committed to excelling in what she does, and is dedicated to delivering optimal service standards to partners and key stakeholders, while accelerating business development and revenue growth. '
        },
        // {
        //   name:'Nicolas Rueda',
        //   role: 'Deputy Head of Sales (Latin America)',
        //   photo:require('@/assets/images/team/Nicolas Rueda.png'),
        //   content: 'Passionate about the evolvement of FinTech and digital assets, Nicolas brings with him over 20 years of senior executive leadership specializing in asset and wealth management, investment and corporate banking, and capital markets. Nicolas worked with global financial corporations, such as Morgan Stanley and Deutsche Bank, covering multi-family offices, ultra HNWI and business start-ups. As an experienced professional, he offers extensive product knowledge, broad commercial astuteness, and analytical insights. At Sparrow, Nicolas will be spearheading the Latin America region, with strategic executions of digital asset solutions for institutional and HNWI clients.'
        // },
        // {
        //   name:'Jonathan Yu',
        //   role: 'Senior Relationship Manager (Institutional Sales)',
        //   photo:require('@/assets/images/team/Jonathan Yu.png'),
        //   content: 'With over 10 years of experience in the Financial Services Industry, Jonathan is an experienced account manager, with extensive knowledge in trading and investing. His experience ranges from global brokering houses, venture capital, and in selling mission critical financial technology. Jonathan is dedicated to delivering trusted and reliable services to all his clients.'
        // },
        {
          name:'Carina Lee',
          role: 'Relationship Manager',
          photo:require('@/assets/images/team/Carina Lee.png'),
          content: 'Carina has over 9 years of professional experience managing high net worth clients’ portfolios as a Relationship Manager in major banks including Standard Chartered Bank, OCBC, and United Overseas Bank. Awarded the Capital Markets & Financial Advisory Service certification, Carina has international banking exposures covering Malaysia and Brunei. Carina believes in maintaining high service standards and driving business growth through a dedicated and long-term fruitful relationship with business partners and clients. '
        },
        {
          name:'Eve Lim',
          role: 'Relationship Manager',
          photo:require('@/assets/images/team/Eve Lim-2.png'),
          content: 'With over 9 years of experience within the banking and finance industry, Eve is committed to managing various profiles of clients including High Net Worths at leading banks such as United Overseas Bank, Standard Chartered Bank, and BNP Paribas. With vast knowledge and professional experience in a full suite of financial products, including insurance, bonds, unit trusts, equity-linked notes, structured products, leverage, and loan switches and as well as premium currency investments, Eve believes in ensuring high compliance towards onboarding in areas of KYC, AML, and CFT. '
        },
        {
          name:'Thaddeus Ong',
          role: 'Senior Trader',
          photo:require('@/assets/images/team/Thaddeus Ong.png'),
          content: 'Thaddeus is a Senior Trader with Sparrow with over 5 years of trading experiences in cryptocurrencies, equities spot and derivatives. In addition, Thaddeus had accumulated strong knowledge of Cryptocurrency market cycles, Altcoin Fundamentals and DeFi Ecosystem. As the founder of the Everything About Bitcoin, Thaddeus is committed to delivering educational insights and tailored FAQ on subjects relating to Bitcoin in the Singapore market. As a specialized trader at Sparrow, Thaddeus monitors market movements, analyzes performances and partners with the digital asset solutions team to offer sound and tailored solutions for financial institutions and wealth managers.'
        },
        /*
        {name:'Alvaro Patron', role: 'Head of Sales', photo:require('@/assets/images/team/Alvaro Patron.png'),
          content: 'Experienced markets head with a demonstrated history of sales and structuring, treasury and corporate finance. His career expands several institutions including Citigroup, Standard Chartered, Deutsche Bank and Nomura in New York, Buenos Aires and Singapore. Skilled in balance sheet management and finance, banking, Foreign Exchange (FX), derivatives and funding, and Capital Markets, Alvaro is a strong finance professional graduated from Tulane University - A.B. Freeman School of Business. With over 26 years of experience. Alvaro has a track record in treasury, business structuring and managing large corporate and institutional organizations across Asia and America.'
        },
        {name:'Jonathan Yu', role: 'Relationship Manager', photo:require('@/assets/images/team/Jonathan Yu.png'),
          content: 'With over 11 years of experience in the Financial Services Industry, Jonathan is an experienced account manager, with extensive knowledge in trading and investing. His experience ranges from global brokering houses, venture capital, and in selling mission critical financial technology. Certified by the Monetary Authority of Singapore in the Financial Market Regulatory Practices, Jonathan is dedicated to delivering trusted and reliable services to all his clients.'
        },
        {name:'Faith Ng', role: 'Relationship Manager', photo:require('@/assets/images/team/Faith Ng-2.png'),
          content: 'Faith has over 11 years of professional experience as a relationship manager managing quality account management with leading brands including Standard Chartered Bank and Shangri La Hotel Singapore. Engaged in diverse industries including commercial banking and hospitality, she has work exposures in Australia, China and Indonesia. Committed to excel in what she does, Faith is dedicated to delivering optimal service standards to partners and key stakeholders, while accelerating business development and revenue growth.'
        },
        {name:'Carina Lee', role: 'Relationship Manager', photo:require('@/assets/images/team/Carina.png'),
          content: 'Carina has over 9 years of professional experience managing high net worth clients’ portfolios as a Relationship Manager in major banks including Standard Chartered Bank, OCBC, and United Overseas Bank. Awarded the Capital Markets & Financial Advisory Service certification, Carina has international banking exposures covering Malaysia and Brunei. Carina believes in maintaining high service standards and driving business growth through a dedicated and long-term fruitful relationship with business partners and clients. '
        },
        {name:'Eve Lim', role: 'Relationship Manager', photo:require('@/assets/images/team/Eve.png'),
          content: 'With over 9 years of experience within the banking and finance industry, Eve is committed to managing various profiles of clients including High Net Worths at leading banks such as United Overseas Bank, Standard Chartered Bank, and BNP Paribas. With vast knowledge and professional experience in a full suite of financial products, including insurance, bonds, unit trusts, equity-linked notes, structured products, leverage, and loan switches and as well as premium currency investments, Eve believes in ensuring high compliance towards onboarding in areas of KYC, AML, and CFT. '
        },
        */
      ],
      isModalVisible: false,
      userSelected: null,
    }
  },
  mounted:function(){
    this.initArrows()
  },
  methods:{
    initArrows:function(){
      $(document).ready(function(){
        $('.c-team__name').each(function(){
          let fullWidth = $(this).width();
          let nameWidth = $(this).find('.c-team__name-text').width();
          let arrowWidth = fullWidth - nameWidth - 5;
          $(this).find('.c-team__arrow-container').width(arrowWidth+'px')
        })
      })
    },
    showModal(dt) {
      this.userSelected = dt
      this.isModalVisible = true;
      $("body").css("overflow-y", "hidden");
    },
    closeModal() {
      this.isModalVisible = false;
      $("body").css("overflow-y", "auto");
    }
  }
}
</script>
