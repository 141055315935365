<template>
  <div class="c-institution">
    <div class="c-institution__title">
      <p class="c-institution__title-text">A full life-cycle of cryptocurrency assets</p>
    </div>
    <div class="c-institution__description">
      <p class="c-institution__description-text">This includes buying cryptocurrencies with fiat money (On-Ramp), using cryptocurrencies as payment (Off-Ramp) and a full suite of cryptocurrency products such as spot, swaps, options services, and OTC derivatives.</p>
    </div>
    <div class="c-institution__body">
      <div class="c-institution__body-content">
        <div class="c-institution__body-content-list">
          <img src="@/assets/images/individual-options.svg">
          <h3 class="c-institution__body-content-list-title">Sparrow Options</h3>
          <p class="c-institution__body-content-list-desc">More customizable than exchange-traded options.</p>
        </div>
        <div class="c-institution__body-content-list">
          <img src="@/assets/images/individual-ssp.svg">
          <h3 class="c-institution__body-content-list-title">Sparrow Structured Products </h3>
          <p class="c-institution__body-content-list-desc">Invest in a range of compelling structured digital asset products designed with compliance in mind.</p>
        </div>
      </div>
      <div class="c-institution__body-content">
        <div class="c-institution__body-content-list">
          <img src="@/assets/images/individual-convert.svg">
          <h3 class="c-institution__body-content-list-title">ConvertNOW Facility (CNF)</h3>
          <p class="c-institution__body-content-list-desc">Instantly swap your digital assets at the best prices on Sparrow. Simple, secure and <span class="c-institution__body-content-list-desc_bold">zero</span> fees.</p>
        </div>
        <div class="c-institution__body-content-list">
          <img src="@/assets/images/individual-apitrading.svg">
          <h3 class="c-institution__body-content-list-title">API Trading</h3>
          <p class="c-institution__body-content-list-desc">Sparrow offers trading APIs and attractive volume-based rebates for professionals.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Individual',
    data() {
      return {

      }
    },
  }
</script>
