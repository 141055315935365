<template>
  <div class="page">
    <div class="c-page o-page-wrapper">
      <Sidebar :status="'institutional'" :elementClass="'.c-getstarted'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content">
          <Content></Content>
          <InstitutionalLifeCycle></InstitutionalLifeCycle>
          <Wealth></Wealth>
          <MeetTheTeam></MeetTheTeam>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import Content from '@/components/institutional/InstitutionContent.vue'
import Wealth from '@/components/institutional/WealthContent.vue'
import InstitutionalLifeCycle from '@/components/institutional/InstitutionalLifeCycle.vue'
import MeetTheTeam from '@/components/institutional/MeetTheTeam.vue'
// import InstitutionTable from '@/components/institutional/InstitutionTable.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'

export default {
  name: 'Institution',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    Content,
    Wealth,
    MeetTheTeam,
    InstitutionalLifeCycle,
    // InstitutionTable,
    LikeSuggestion,
    GetStartedContent
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner-6.svg'),
          title:"<span class='c-page-banner__regular c-page-banner__regular-longtext'>Sparrow aims to meet</span>",
          subtitle:"<span class='c-page-banner__bold c-page-banner__mobile'>the highest compliance <br />and operational standards</span>",
          buttonText:"Find out more >",
          buttonPage:"/legal/regulation"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Bespoke Digital Asset Solutions with Specialized Service')
    this.updateDescriptionMeta("Sparrow's digital asset products are customized for various market postures and aligned with institutions’ growth objectives to hedge and manage positions optimally.")
    this.updateKeywordMeta('Sparrow, sparrow exchange, wealth management')
  }
}
</script>
